var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("van-overlay", { attrs: { show: _vm.showoverlay } }, [
    _c(
      "div",
      {
        staticClass: "wrapper",
        on: {
          click: function ($event) {
            $event.stopPropagation()
          },
        },
      },
      [
        _c("div", { staticClass: "animation", attrs: { id: "lottie_box" } }),
        _c("div", [_vm._v("即将上线，敬请期待")]),
        _c("div", {
          staticClass: "close iconfont icon-cancel",
          on: { click: _vm.overlayClick },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }