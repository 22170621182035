<template>
  <van-overlay :show="showoverlay">
    <div class="wrapper" @click.stop>
      <div id="lottie_box" class="animation"></div>
      <div>即将上线，敬请期待</div>
      <div @click="overlayClick" class="close iconfont icon-cancel"></div>
    </div>
  </van-overlay>
</template>
<script>
import lottie from 'lottie-web'
export default {
  name: 'Loading',
  components: {},
  props: {

  },
  data() {
    return {
      showoverlay: false,
    }
  },
  computed: {},
  watch: {
    show: {
      handler(val) {
        this.showoverlay = val
        // console.log('show', val)
      },
      immediate: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.$nextTick(() => {
      lottie.loadAnimation({
        container: document.getElementById(`lottie_box`), // 包含动画的dom元素
        renderer: 'svg', // 渲染出来的是什么格式
        loop: true, // 循环播放
        autoplay: true, // 自动播放
        animationData: require(`@/assets/lottie/bg.json`), // 动画json的本地路径
        // path:''//网络json地址, 选择 animationData 后，则不用path了，
      })
    })
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    overlayClick() {
      console.log(111111111)
      this.showoverlay = false
    },
    showOverlay() {
      this.showoverlay = true
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  //   .animation{
  //     width: ;
  //   }
  .close {
    font-size: 35px;
    margin-top: 10px;
  }
}
</style>
